export const languageHashValues= {
  "GLOBAL": ".4c86324333b8eb5de9b9",
  "U4302_TRANSACTIONSDETAIL": ".e37206388e180cdbbd75",
  "U4400_PAYMENT": ".a748499c0af77a3c4808",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".ec982c7dbe44dd82c4ee",
  "U2117_PADAGREEMENTINVITATION": ".6e338eefbb823e863284",
  "U2000_PAD": ".000150d868ecdb658a74",
  "U4402_INVOICEPORTALPAYMENT": ".85968d31dc20b1f86d9b",
  "U2000_BANKACCOUNTCREATION": ".da9e24691f22ad90378c",
  "U2011_LOGIN": ".eb780447fb9adcd63145",
  "U2012_LOGINHELP": ".1f77bb4ce39793e1ad5c",
  "U2014_SECURITY": ".cfbebaf69910cf2e2608",
  "U2086_EFTPENDINGPAYMENTS": ".db481d43f4cee79fec2f",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".8d01580c7a97c9a2a259",
  "U2110_ACCOUNTCREATION": ".636e482be48e6b8f5e52",
  "U2121_INDIVIDUALPROFILE": ".af9dcb09ccab930dcc9f",
  "U2131_ORGANIZATIONPROFILE": ".0d6b7efe13e006ede962",
  "U2112_ERPBINDING": ".5a2c2b023e0b7ed4fe8f",
  "U2113_SUPPLIERINVITATION": ".31b1fa4a7273ac28f9fd",
  "U2118_RBBINDING": ".92d2f649eae1c2f1eb67",
  "U2132_AFFILIATION": ".cf76b743feb311aa06e6",
  "U2133_BUSINESSLINKS": ".9765dd0817f60c68a067",
  "U2181": ".ba14c5abce6f9f4b75d9",
  "U2211_SUBSCRIPTIONOFFER": ".2ed0f25057647b2e8f5e",
  "U2212_SUBSCRIPTIONFILTER": ".be3583818746447972c3",
  "U2213_SUBSCRIPTION": ".885a8138cf8be23f9e3d",
  "U4111_CREDITCARDADDITION": ".9ac85f291be6149e9c56",
  "U4112_CREDITCARDDETAIL": ".c12e583ef2f7749e2075",
  "U4121_BANKACCOUNTADDITION": ".18182887db907bf5c9b4",
  "U4122_BANKACCOUNTDETAIL": ".b0803894d0c231283760",
  "U4303_ANONYMOUSTRANSACTION": ".e3754aadd74c655de1ee",
  "U4401_PAYMENTGATEWAY": ".9d81694c03ffc242e762",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".db7f9d920fd0cab06652",
  "U5000_RECEIVABLE": ".e55c65fd26c88aeeaa03",
  "U5101_STATEMENTOFACCOUNT": ".fc0726cfd9df9a5bb9dd",
  "U5102_INVOICE": ".4fa9d0b4838e400ab8e3",
  "U5103_PADAGREEMENT": ".d5862e1836e6f7f9135b",
  "U5121_SENTINVOICE": ".5749fffb42e8727cc5c3",
  "U5124_PGRECEIVEDPAYMENT": ".e8405c46d030863e22c8",
  "U5120_INVOICES": ".d60216db940d8e4fa4f6",
  "U5123_PADAGREEMENTCONSOLE": ".45053339e2d8ee03756e",
  "U5132_PAYMENTRECONCILIATION": ".43ba64e8ef419dba1d00",
  "U5133_BANKRECONCILIATION": ".c8caa7c342fcad9f4703",
  "U6112_BUSINESSLINK": ".7b384b5af7b0610a71ef",
  "U6113_SUPPLIERRECORD": ".e2e563745d2a6f869677",
  "U6211_PAYLOT": ".ef2d9a12b08b7c95d95e",
  "U6311_PURCHASE": ".5ec7f87853c13505c867",
  "U2115_AFFILIATIONINVITATION": ".598055b9b7210d31738e",
  "U6100_PAYABLE": ".6df7e380efd22ad39dcb",
  "U6131_SENTPAYMENT": ".15fa25402aa97268ae41",
  "U6191_SENTPAYMENTREPORT": ".53b906f4e2ff1d0f6239",
  "U6221_SENTPAYMENT": ".f655f256e9582ff4091a",
  "U6422_INTERACSENTPAYMENT": ".b5940d2d44a83ce0cf5c",
  "U6531_TRANSACTIONPAYMENT": ".1f707219a4d5567d62a1",
  "U5131_RECEIVEDPAYMENT": ".4b5107581ddbddf99a93",
  "U5191_RECEIVEDPAYMENTREPORT": ".003aea17304c44cacb86",
  "U2001_HOME": ".53407f6f8cf487d67e16",
  "U2024_REPORTLOG": ".b09c52d5c8d2dd624539",
  "U2111_INVOICEPORTALBINDING": ".65789c8cc02c7b3e400d",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".0de05fb42f8cc9f8196b",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".0f9dc433a7f763902911",
  "U6511_TRANSACTIONPARTY": ".f081caaa5f36e22fd42c",
  "U6212_PAYROLLPARTY": ".b77ddbcdeb03d3eb1ff7",
  "U6121_EFTLOT": ".d99b588922da64058ff8",
  "U6412_INTERACFILE": ".b40182b98e06ca18b5fc",
  "U6122_EFTAPPROVAL": ".e45eab18fba70664491a",
  "U6512_TRANSACTIONLOT": ".e369a5b5bd1baae306d1",
  "U6123_EFTAPPROVAL": ".57742b7ef184dc4a0b71",
  "U6123_EFTAPPROVALMOBILE": ".95f762e41ccdc92691a5",
  "U2012_LOGIN": ".8933415592997da3d31f",
  "U2000_PAYMENT": ".59135634cb6b6ead3591",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".40b221ea400f1e42fcaf",
  "U2000_PAYMENTMODEUPDATE": ".5322d0234ef9995b46dc",
  "U6114_TELUSBILLER": ".9d9d46e17e01d41ff66c",
  "U4403_CREDITCARDTERMINAL": ".740928e3e2d97bc09835",
  "U4404_INTERACTERMINAL": ".0258780371344fbd223a",
  "U2000_SAMPLECHEQUESELECTION": ".948475220fb81e3204c6",
  "U2023_TUTORIALVIDEO": ".e0f6c7c94f09b260976f",
  "U2134_ORGANIZATIONBILLING": ".b80958d35f18ced57411",
  "U6414_BANKACCOUNTWALLET": ".eefa011ec8a5feb9abfc",
  "U2022_INSTANTACCOUNTVERIFICATION": ".d45524959881dd3e2558",
  "U6532_PENDINGTRANSACTION": ".b96c54e5dbd4f2f86cd2",
  "U4301_TRANSACTIONSSUMMARY": ".3ff36e2b39a98fc9bdb3",
  "U5111_SENTINVOICESTRACKING": ".df99b4d98b91e14dd4ae",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".320413db0e9e15a9da54",
  "U2000_INTRO": ".d97d027be15eb47619fc",
  "U2000_ELECTRONICSIGNATURE": ".8cae8ace7552428d8f9b",
  "U2000_VERIFYEMAIL": ".4aa8b3e0f1e23de4f841",
  "U2200_SUBSCRIPTION": ".99f316d3ec08ed6c092d",
  "U2000_VERIFYIDENTITY": ".337c991f531ce33aa679",
  "U2000_ATTACHMENTVIEWER": ".4fe5d6736e83d2622a06",
  "U2212_PLANLIST": ".8f2305d722640fc952e9",
  "U3002_TAXBILLSEARCHRESULT": ".ed47ef52572ce63a8ff2"
}