import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import localeEnCA from '@angular/common/locales/en-CA';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFrCA from '@angular/common/locales/fr-CA';
import { Injectable, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { defineLocale, enGbLocale, frLocale } from 'ngx-bootstrap/chronos';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { BusyIndicatorInterceptor } from '../gamma/busyindicator';
import { HttpService, IHttpOptions } from '../gamma/http/http.service';
import { I18nService } from '../gamma/i18n/i18n.service';
import { L10nService } from '../gamma/l10n/l10n.service';
import { LocalStorageService } from '../gamma/localstorage';
import { ReferentialService } from '../gamma/referential';
import { UserAgentService } from '../gamma/utils';

import { environment } from '../environments/environment';
import { U2000_AuthenticationService, U2000_BaseHttpInterceptor } from './U2000_core';
import { U2000LS_MappingKey, U2000LS_TenantId, U2000LS_TokenExpiredRedirection, U2000LS_TokenInfo } from './U2000_core/U2000_localstorages';
import { U2000RF_Languages, U2000RF_LanguagesInverted } from './U2000_core/U2000_referencials';

window['angularHasBeenBootstrapped'] = true;

window['forceLanguageLogicalUnit'] = {
    U2212: 'fr',
    //U2213: 'fr',
};

@Injectable()
export class AppInitService {
    initData: IU2000_CoreInitDto;
    constructor(
        private http: HttpClient,
        private httpService: HttpService,
        private baseHttpInterceptor: U2000_BaseHttpInterceptor,
        private busyIndicatorInterceptor: BusyIndicatorInterceptor,
        private translate: TranslateService,
        private l10nService: L10nService,
        private i18nService: I18nService,
        private referentialService: ReferentialService,
        private localStorageService: LocalStorageService,
        private userAgentService: UserAgentService,
        private injector: Injector,
    ) {}

    load(): Promise<void> {
        // If the custom page is refreshed, try routing to the login.
        if (location.hash.indexOf('#/U2000/e') > -1 || location.hash.indexOf('#/U2000/w') > -1 || location.hash.indexOf('#/U2000/c') > -1) {
            location.hash = '#/U2011';
        }

        return this.initApplication()
            .toPromise()
            .then(() => {
                this.initCoreTranslations();
            });
    }

    public initCoreTranslations() {
        return this.translate.use(this.i18nService.currentLanguageCode);
    }

    public initApplication() {
        let search = new HttpParams();
        search = this.extractBundleId(search);
        search = this.extractUserMapping(search);

        // PATCH: SAIA-14706: Remove U6121 sort order from the local storage.
        if (this.localStorageService.get('PATCH-SAIA-14706-Executed') == null) {
            this.localStorageService.remove('U6121_searchSortOrder');
            this.localStorageService.remove('U6122_searchSortOrder');
            this.localStorageService.set('PATCH-SAIA-14706-Executed', true);
        }

        return this.http
            .get<IU2000_CoreInitDtoResponse>(
                environment.apiUrl + 'U2000/init',
                this.httpService.optionsWithExtra({
                    params: search,
                    headers: new HttpHeaders().set('Accept-Language', this.i18nService.currentLanguageCode),
                    globalErrorHandling: false,
                } as IHttpOptions),
            )
            .pipe(
                tap(res => {
                    this.initData = res.result;
                    // Must affect to the auth service because of circular dependency
                    this.injector.get(U2000_AuthenticationService).mappingWorkflowReference = this.initData.mappingWorkflowReference;

                    this.referentialService.add(U2000RF_LanguagesInverted, this.initData.languages);
                    this.referentialService.add(U2000RF_Languages, this.invertLanguages(this.initData.languages));

                    this.initialize();
                }),
                catchError((res: Response) => {
                    // If the token is expired, clear the local storage and reload.
                    if (res.status === 401) {
                        const tenant = this.localStorageService.get(U2000LS_TenantId);

                        this.localStorageService.remove(U2000LS_TokenInfo);
                        this.localStorageService.remove(U2000LS_TenantId);
                        this.localStorageService.set(U2000LS_TokenExpiredRedirection, { url: location.hash.substr(1), tenant });
                        location.reload();
                    } else {
                        // We don't use the router beacause it is not usable at this time.
                        location.hash = '#/U2000/e/m';
                        return of();
                    }
                    return throwError(res);
                }),
            );
    }

    private extractBundleId(search: HttpParams) {
        if (window.location.hash.indexOf('?') > -1) {
            const luTarget = window.location.hash.substr(2, 5);

            let hash = window.location.hash.substring(window.location.hash.indexOf('?') + 1);

            const urlParams = new URLSearchParams(hash);
            const bundleId = urlParams.get('bundleId');
            if (bundleId != null) {
                search = search.set('bundleId', bundleId);
                search = search.set('luTarget', luTarget);

                //urlParams.delete('bundleId');
                urlParams.set('bundleId', 'true');

                window.location.hash = window.location.hash.substring(0, window.location.hash.indexOf('?') + 1) + urlParams.toString();
            }
        }

        return search;
    }

    private extractUserMapping(search: HttpParams) {
        if (window.location.hash.indexOf('?') > -1) {
            const luTarget = window.location.hash.substr(2, 5);

            let hash = window.location.hash.substring(window.location.hash.indexOf('?') + 1);

            const urlParams = new URLSearchParams(hash);
            const auth = urlParams.get('auth');
            if (auth != null) {
                search = search.set('workflowReference', auth.substring(auth.indexOf('-') + 1));
                search = search.set('luTarget', luTarget);

                urlParams.delete('auth');

                const applicationId = auth.substring(0, auth.indexOf('-'));
                let key = btoa(`${U2000LS_MappingKey}_${applicationId}`);
                key = key.substr(0, key.length - 2);

                const appMappings = this.localStorageService.get<string[]>(key);
                if (!Array.isNullOrEmpty(appMappings)) {
                    search = search.set('mapping', appMappings.join());
                }

                window.location.hash = window.location.hash.substring(0, window.location.hash.indexOf('?') + 1) + urlParams.toString();
            }
        }
        return search;

        /* const authStr = 'auth=';
        const lastEmpercentPos = window.location.hash.lastIndexOf('&');
        const authPos = window.location.hash.indexOf(authStr);

        if (authPos >= 0) {
            let auth: string;
            let newHash = window.location.hash.substr(0, authPos - 1);
            auth = window.location.hash.substr(authPos + 'auth='.length);
            if (lastEmpercentPos > authPos) {
                const nextEmpercentPos = auth.indexOf('&');
                newHash = newHash + auth.substr(nextEmpercentPos);
                auth = auth.substr(0, nextEmpercentPos);

            }
            search = search.set('workflowReference', auth.substring(auth.indexOf('-') + 1));

            const applicationId = auth.substring(0, auth.indexOf('-'));
            let key = btoa(`${U2000LS_MappingKey}_${applicationId}`);
            key = key.substr(0, key.length - 2);

            const appMappings = this.localStorageService.get<string[]>(key);
            if (!Array.isNullOrEmpty(appMappings)) {
                search = search.set('mapping', appMappings.join());
            }

            window.location.hash = newHash;
        }
        return search; */
    }

    initialize() {
        this.initializeHttp();
        this.initializeI18n();
        this.initializeL10n();
        this.initializeUserAgent();
    }

    initializeHttp() {
        this.httpService.addInterceptor(this.busyIndicatorInterceptor);
        this.httpService.addInterceptor(this.baseHttpInterceptor);
    }

    initializeI18n() {
        if (this.i18nService.currentLanguageCode) {
            registerLocaleData(localeFrCA, 'fr', localeFrExtra);
            registerLocaleData(localeEnCA, 'en');
        }

        // this language will be used as a fallback when a translation isn't found in the current language
        // TODO: Not sure how we want this thing to work.
        // this.translate.setDefaultLang('fr');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        // this.translate.use(this.i18nService.currentLanguageCode);
    }

    initializeL10n() {
        if (this.i18nService.currentLanguageCode === 'fr') {
            this.l10nService.localeId = 'fr';
            this.l10nService.dateFormats['medium'] = this.initData.appConfig.mediumDate;
            this.l10nService.dateFormats['full'] = this.initData.appConfig.fullDateAndTime;
            this.l10nService.decimalSeparator = '.';
            this.l10nService.thousandSeparator = String.fromCharCode(160);
        } else {
            this.l10nService.localeId = 'en';
            this.l10nService.dateFormats['medium'] = this.initData.appConfig.mediumDate;
            this.l10nService.dateFormats['full'] = this.initData.appConfig.fullDateAndTime;
            this.l10nService.decimalSeparator = ',';
            this.l10nService.thousandSeparator = String.fromCharCode(160);
        }
        defineLocale('fr', frLocale);
        defineLocale('en', enGbLocale);
    }

    initializeUserAgent() {
        document.body.setAttribute('data-os', this.userAgentService.os);
        document.body.setAttribute('data-browser', this.userAgentService.browser);
    }

    // Fix the inverted referential coming from the server.
    private invertLanguages(languages: ILanguageDto[]) {
        let language: ILanguageDto;
        if (this.i18nService.currentLanguageCode === 'fr') {
            language = languages[languages.findIndex(x => x.code === 'en')];
        } else {
            language = languages[languages.findIndex(x => x.code === 'fr')];
        }
        language.labels.push(language.labels[0]);
        language.labels.splice(0, 1);

        return languages;
    }
}
